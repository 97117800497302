.App {
  background: dodgerblue;
    width: 800px;
    height:600px;
    display:flex;
    align-items:center;
    justify-content:center;
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Lobster', cursive;
}

h1 {
  display:flex;
  justify-content: center;
}
